// ANCHOR Model
import createModel from '@lxsmnsyc/react-scoped-model';

// ANCHOR Graph State
import { useUserAgent } from '@lyon/nodes/global/user-agent-node';

// ANCHOR Hooks
import { useWindowSize } from '@lyon/utils/hooks/useWindowSize';

interface IState {
  isMobile: boolean;
  isDesktop: boolean;
}

export const MobileView = createModel<IState>(
  () => {
    const { isMobile, isTablet, isDesktop } = useUserAgent();
    const { width, loading } = useWindowSize();

    if (loading) {
      return {
        isDesktop,
        isMobile: isMobile || isTablet,
      };
    }

    return {
      isMobile: width <= 768,
      isDesktop: width > 768,
    };
  },
  {
    displayName: 'Models.MobileView',
  },
);

// ANCHOR Model
import createModel from '@lxsmnsyc/react-scoped-model';

// ANCHOR Hooks
import { useToggle } from '@lyon/utils/hooks/useToggle';

export const SidebarVisibility = createModel(
  () => {
    // Toggler
    const control = useToggle(true);

    return control;
  },
  {
    displayName: 'Models.SidebarVisibility',
  },
);

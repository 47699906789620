// ANCHOR React
import React from 'react';

// ANCHOR Models
import { MobileView } from '@lyon/scoped-models/MobileView';
import { NavbarSearchQuery } from '@lyon/scoped-models/navbar/NavbarSearchQuery';
import { SidebarVisibility } from '@lyon/scoped-models/sidebar/SidebarVisibility';
import { FirebaseRemoteConfig } from '@lyon/scoped-models/firebase/FirebaseRemoteConfig';
import { compose } from './Compose';

export const RootProvider = compose(
  /**
   * ANCHOR Provider for FirebaseRemoteConfig
   */
  <FirebaseRemoteConfig.Provider />,

  /**
   * ANCHOR Provider for the MobileView
   *
   * NOTE Used for monitoring orientation changes
   */
  <MobileView.Provider />,

  /**
   * ANCHOR Provider for the SidebarVisibility
   *
   * NOTE Used for handling sidebar state
   */
  <SidebarVisibility.Provider />,
);

export const PageProvider = compose(
  /**
   * ANCHOR Provider for NavbarSearchQuery
   *
   * NOTE Used for performing search queries
   */
  <NavbarSearchQuery.Provider />,
);

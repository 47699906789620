// ANCHOR React
import * as React from 'react';

// ANCHOR Base
import { Value } from 'baseui/select';

// ANCHOR Hooks
import { useConstantCallback } from './useConstantCallback';

export function useSearchQuery() {
  const [selected, setSelected] = React.useState<Value>([]);
  const [queries] = React.useState([]);

  const search = useConstantCallback(() => {
    // TODO Query logic
  });

  const select = useConstantCallback((value: Value) => {
    setSelected(value);
  });

  const clear = useConstantCallback(() => {
    setSelected([]);
  });

  return React.useMemo(() => (
    {
      select,
      clear,
      search,
      queries,
      selected,
    }
  ), [
    select,
    clear,
    search,
    queries,
    selected,
  ]);
}

// ANCHOR React
// import React from 'react';

// ANCHOR Scoped Model
import createModel from '@lxsmnsyc/react-scoped-model';

// ANCHOR Hooks
// import { FirebaseApp } from './FirebaseApp';

export const FirebaseRemoteConfig = createModel(() => (
  // const currentApp = FirebaseApp.useSelector((state) => state.app);

  // return React.useMemo(() => {
  //   if (currentApp) {
  //     const remoteConfig = currentApp.remoteConfig();

  //     remoteConfig.defaultConfig = {
  //       maintenance_mode: false,
  //     };

  //     return {
  //       remoteConfig,
  //     };
  //   }
  //   return {};
  // }, [currentApp]);
  {}
), {
  displayName: 'Models.FirebaseRemoteConfig',
});

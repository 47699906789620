import * as React from 'react';
import { useConstantCallback } from './useConstantCallback';

export function useToggle(initialState = false) {
  const [state, setState] = React.useState(initialState);

  const show = useConstantCallback(() => setState(true));
  const hide = useConstantCallback(() => setState(false));
  const toggle = useConstantCallback(() => setState((s) => !s));

  return React.useMemo(() => (
    {
      state, show, hide, toggle,
    }
  ), [hide, show, state, toggle]);
}
